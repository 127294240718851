import React from "react"
import StyledLink from '@typo/StyledLink'
import Text from '@typo/Text'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Header from "@menus/header"



const Wrapper = styled.div`
  background-color: white;
  max-width:1200px;
  padding: 80px 8% 0 8%;
  margin:auto;
  min-height: 100vh;
  @media (min-width: 768px) {
    padding: 10% 8% 0 8%;
  }
`

const Terms = (props) => {
  const { t } = useTranslation(['pages','translations'])

  return (
    <>
    <Header location={props.location} alwaysShow={true}/> 
        <Wrapper>
        <Text>{t('pages:facebook-login.hello')}</Text>
        <Text dangerouslySetInnerHTML={{ __html: t('pages:facebook-login.text')}}></Text>
        <Text> 
            <ol>
              <li>{t('pages:facebook-login.goTo')}<StyledLink  to={t('translations:urls.reset-password')}>{t('pages:facebook-login.resetPassword')}</StyledLink></li>
              <li>{t('pages:facebook-login.enterEmail')}</li>
              <li>{t('pages:facebook-login.pressButton')}</li>
              <li>{t('pages:facebook-login.checkEmail')}</li>
              <li>{t('pages:facebook-login.login')}</li>
            </ol>
            {t('pages:facebook-login.bottomText-1')}<StyledLink href="mailto:hello@audiodesires.com"> hello@audiodesires.com.</StyledLink>{t('pages:facebook-login.bottomText-1')}
            <br></br><br></br>
            {t('pages:facebook-login.team')}
            <br></br><br></br>
          </Text>
      </Wrapper>
      </>
  )
}
export default Terms